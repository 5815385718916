@media (max-width: 1240px) {
  .display-entity-card {
    width: auto;
    height: 250px;
    cursor: pointer;
  }

  .base-map {
    height: 350px;
    width: 79px;
    background-color: var(--default-white);
    position: absolute;
    bottom: 90px;
    left: 0;
    border-radius: 8px;
    box-shadow: 0 1px 3px var(--default-box-shadow-color),
      0 4px 8px 3px var(--secondary-box-shadow-color);
    padding: 10px 16px 6px;
    display: flex;
    flex-direction: column;
  }

  .scenario-map-series {
    /* width: calc(100% - 348px) !important;
    position: absolute !important;
    background-color: var(--primary-background) !important;
    bottom: 10px;
    height: 140px;
    color: var(--default-black);
    padding-left: 30px;
    display: block;
    padding-bottom: 30px;
    right: 100px;
    left: 350px; */
    
  }

  .la-selection .ant-tabs-nav-list {
    display: flex;
    flex-direction: column;
  }

  .progress-main-page {
    display: block;
    padding-bottom: 4em;
  }

  .progress-steps {
    width: 100%;
    overflow-x: scroll;
    margin-top: 30px;
    padding: 10px 0;
  }

  .access-manage-guidance {
    width: 100%;
    margin-top: 10px;
  }

  .access-manage-table {
    width: 100%;
    margin-bottom: 10px;
  }

  .access-guidance {
    border-top: 4px solid var(--default-cta);
    background-color: var(--default-white);
    height: auto;
    overflow-y: scroll;
    padding: 15px 10px 0 20px;
  }

  .manage-access {
    width: 100%;
    padding: 1.5rem 2rem;
    height: 80%;
    overflow-y: scroll;
    padding-top: 0;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10px;
  }

  .access-main-page {
    width: 100%;
    background-color: var(--default-white);
    margin-right: 0;
  }

  .invite-page {
    width: 100%;
    height: 80%;
    padding-top: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10px;
  }

  .invite-card {
    width: 100%;
    margin-top: 5px !important;
    overflow-y: unset;
  }

  .datasets {
    width: auto;
  }

  .row-style {
    height: 90%;
    overflow-y: scroll;
    margin: 0 !important;
  }

  .guidance-page {
    padding: 24px;
    margin: 0px 24px;
    display: block;
    height: 88vh !important;
    overflow-y: scroll;
  }

  .guidance-menu {
    padding: 16px !important;
    height: auto;
    overflow-y: scroll !important;
  }

  .guidance-content {
    background-color: var(--default-white);
    margin: 0 auto;
    width: 100%;
    height: auto;
  }

  .usecase-page {
    margin: 0 10px;
    background-color: var(--default-white);
    padding: 30px 50px;
  }

  .blue-box {
    width: 100%;
  }

  .usecase-step1 {
    display: block;
  }

  .usecase-step1-blue-box {
    width: 100%;
  }

  .usecase-step1-question-box {
    margin-left: 0;
    width: 100%;
  }

  .timeseries-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    /* margin-top: 75px; */
    margin-left: 0;
  }

  .scenario-map-series-slider {
    width: 100% !important;
    margin: 0;
    color: var(--default-black);
  }


  .scenario-commom-card {
    display: block;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 40px;
  }

  .reports-layout {
    height: 100vh;
    margin: 5px;
    display: block;
  }

  .reports-page-sider {
    flex: 0 0 20vw !important;
    max-width: 20vw !important;
    min-width: 100% !important;
    width: auto !important;
    background: none;
    margin-bottom: 10px;
  }

  .scenario-main-page {
    padding: 0;
    /* padding-top: 40px; */
    width: 100%;
    height: 90%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
  }

  .scenario-left-page {
    width: 100%;
    height: auto;
    margin-right: 0;
    overflow-y: unset;
    padding-right: 10px;
    padding-bottom: 1em;
  }

  .scenario-form {
    margin-top: 10px;
    display: grid;
    grid-template-areas: ". ." ". .";
    grid-row-gap: 20px;
    grid-column-gap: 5em;
    padding: 30px 0;
    padding-top: 10px;
  }

  .scenario-budget-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
  }

  .scenario-guidance-page {
    width: 100%;
  }

  .analytics-guide {
    height: auto;
    overflow-y: scroll;
    padding-right: 0;
  }

  .legend {
    width: 275px;
    left: -100px;
  }

  .dataset-info {
    width: fit-content;
    left: -250px;
  }

  .map-tool-items .ant-space {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .manage-access-main-page {
    background-color: var(--primary-background);
    margin: 5px 5px 5px 0px;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 99.5%;
    flex-wrap: wrap;
    gap: 12px;
  }

  .assets-guide {
    border-top: 4px solid var(--default-cta);
    background-color: var(--default-white);
    height: auto;
    overflow-y: scroll;
    margin-right: 0;
    padding: 15px 10px 20px 20px;
  }

  .manage-access-card {
    padding: 1rem 1rem;
    height: 63vh;
  }

  .usecases-main-page {
    margin: 0;
    background-color: var(--default-white);
    padding: 30px 50px;
  }
  
  .map-tools-zoom-space {
        bottom: 50%;
  }

  .D-map-tools-zoom-space {
    bottom: 50%;
}

  .progress-tracker-side-boxes {
    height: 100%;
    width: auto;
    padding: 20px;
    padding-top: 50px;
  }

  .progress-tracker-left-page {
    background-color: var(--default-white);
    padding-top: 40px;
    padding-left: 20px;
    width: auto;
  }
}

