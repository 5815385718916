.default-btn {
  background-color: var(--default-grey) !important;
  color: var(--default-white) !important;
}

.default-run-senario-btn {
  background-color: var(--default-cta) !important;
  color: var(--default-white) !important;
}

.default-run-senario-btn-disable {
  background-color: var(--light-text) !important;
  color: var(--default-white) !important;
}

.default-text {
  color: var(--default-white) !important;
  margin: 0px !important;
}

.secondary-text {
  /* color: var(--default-grey) !important; */
  margin: 0px !important;
}

.home-contents {
  width: 100%;
  height: 87vh;
  overflow-y: scroll !important;
  padding: 24px;
}

.controll .ant-typography {
  transition: 0.5s ease-out;
  color: var(--default-black);
}

.controll .ant-drawer-content {
  border-radius: 20px !important;
}

.controll h5.ant-typography {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 1.8;
  color: var(--default-black) !important;
  letter-spacing: 0.15px;
  font-weight: 400;
}

.controll .ant-card-body:hover .ant-typography {
  transition: 0.2s ease-in;
  /* color: #fff !important; */
}

.controll svg {
  transition: 0.5s ease-out;
}

.controll svg:hover:not(.bar-plot svg) {
  /* color: aliceblue; */
  transition: 0.2s ease-in;
  transform: scale(1.1);
}

.controll button:not(.layer button) {
  border: 0;
}

.controll button:active:not(.layer button) {
  transform: scale(0.95);
}

.controll .ant-tabs-nav-operations {
  display: none !important;
}

.controll .ant-tabs-content-holder:not(.category-tab .ant-tabs-content-holder,
  .assets-tab .ant-tabs-content-holder) {
  padding: 16px;
  width: 100% !important;
  height: auto;
}

/* .layer {
  margin-bottom: 12px;
} */

.map-tools-icon {
  outline: none !important;
  border: none !important;
}

.custom-tools>.ant-btn {
  border-radius: 4px !important;
  box-shadow: 0 1px 2px var(--default-box-shadow-color),
    0 1px 3px 1px var(--secondary-box-shadow-color);
  font-size: 0.775rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.25rem;
  padding: 0 12px;
  padding-bottom: 2px;
  height: 30px;
}

.custom-tools .map-tools-icon {
  border-radius: 4px !important;
  box-shadow: 0 1px 2px var(--default-box-shadow-color),
    0 1px 3px 1px var(--secondary-box-shadow-color);
  font-size: 0.775rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.25rem;
  padding: 0 12px;
  padding-bottom: 2px;
  height: 30px;
}

.map-tools-zoom-icon {
  border-radius: 5px !important;
  border: none !important;
  height: 25px !important;
  width: 28px !important;
}

.map-tools-zoom-space {
  gap: 3px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  position: absolute;
  bottom: 50%;
  right: 15px;
  background: white;
  border-radius: 5px;
}

.D-map-tools-zoom-space {
  gap: 3px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  position: absolute;
  bottom: 5%;
  right: 5px;
  background: white;
  border-radius: 5px;
}

.expand-controls {
  position: absolute !important;
  border: 0 !important;
  background-color: var(--primary-background) !important;
  color: var(--default-white) !important;
  top: 45vh;
  width: 23px !important;
  height: 48px !important;
  cursor: pointer;
  border-left: 1px solid var(--light-text);
  border-radius: 0 8px 8px 0 !important;
  background: var(--default-white) 7px center/7px 10px no-repeat;
  box-shadow: 0 1px 2px var(--default-box-shadow-color),
    0 2px 6px 2px var(--secondary-box-shadow-color);
}

.expand-controls:active,
.expand-controls:hover {
  background-color: var(--primary-background) !important;
  color: var(--default-white) !important;
}

.map-tools {
  position: absolute !important;
  top: 16px;
  right: 10px;
}

.custom-tools .map-tools-icon {
  box-shadow: 0 1px 2px var(--default-box-shadow-color),
    0 1px 3px 1px var(--secondary-box-shadow-color);
  font-size: 0.775rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.25rem;
  padding: 0 12px;
  padding-bottom: 2px;
  height: 30px;
  color: var(--default-black);
}

.map-tools .ant-space-item {
  box-shadow: var(--default-box-shadow-color) 0px 48px 100px 0px !important;
}

.map-tools .ant-popover-inner-content {
  padding: 0px !important;
}

.time-series.ant-card {
  background: transparent !important;
}

.time-series .ant-card-head-title,
.time-series .ant-card-extra {
  padding: 0px !important;
}

.time-series .ant-card-head {
  border: none !important;
}

.time-series .ant-card-body {
  background-color: var(--default-background) !important;
}

.time-series .ant-slider-handle-1::after {
  content: "";
  position: absolute;
  top: -65px;
  left: 5px;
  height: 35px;
  width: 4px;
  background-color: var(--light-blue);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: ew-resize;
}

.time-series .ant-slider-track-1::before {
  content: "";
  position: absolute;
  top: -90px;
  height: 75px;
  width: 100%;
  background-color: var(--default-grey);
  opacity: 0.15;
  border-radius: 5px;
  cursor: move;
}

.time-series .ant-slider-handle-2::before {
  content: "";
  position: absolute;
  top: -65px;
  right: 5px;
  height: 35px;
  width: 4px;
  background-color: var(--light-orange);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: ew-resize;
}

.time-series-info {
  position: absolute !important;
  bottom: 16px;
  left: 33%;
  transform: translateX(-5%);
  background-color: var(--default-background);
  width: 55%;
  height: 250px;
  padding: 12px;
}

.time-series-info .ant-page-header-heading {
  display: flex;
  align-items: center;
}

.time-series-info .ant-page-header-content {
  height: 160px !important;
  display: flex;
  align-items: flex-end !important;
}

.time-series-slider .ant-dropdown-menu-item:hover {
  background: none;
}

.time-series-graph {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 58px;
}

.time-series-graph .ant-slider-handle-1::after {
  content: "";
  position: absolute;
  top: -80px;
  left: 5px;
  height: 45px;
  width: 4px;
  background-color: var(--light-blue);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: ew-resize;
}

.time-series-graph .ant-slider-track-1::before {
  content: "";
  position: absolute;
  top: -115px;
  height: 102px;
  width: 100%;
  background-color: var(--default-grey);
  opacity: 0.25;
  border-radius: 5px;
  cursor: move;
}

.time-series-graph .ant-slider-handle-2::before {
  content: "";
  position: absolute;
  top: -80px;
  right: 5px;
  height: 45px;
  width: 4px;
  background-color: var(--light-orange);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: ew-resize;
}

.time-series-graph .rv-xy-plot__grid-lines__line {
  /* stroke: var(--default-blue); */
  stroke-dasharray: 1px 4px;
}

.time-series-graph .rv-xy-plot__axis--horizontal {
  transform: translateY(115px);
}

.time-series-graph .rv-xy-plot__axis__tick__text {
  font-size: 9px;
  fill: var(--default-hover-color);
}

.time-series-graph .rv-xy-plot__inner {
  /* important to show axis */
  overflow: visible;
}

.time-series-tools {
  width: 100%;
  justify-content: flex-end;
}

.map-styles-collapse>.ant-collapse-header {
  height: 50px;
  padding: 2px 16px !important;
  background-color: var(--secondary-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.map-styles-collapse>.ant-collapse-header:hover {
  background: var(--shade);
}

.map-styles-collapse .ant-typography {
  float: left;
  margin-top: 8px;
  color: var(--default-grey);
}

.map-styles-collapse svg {
  color: var(--default-grey);
  font-size: 14px;
}

.map-styles-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 0 !important;
  margin: 8px 0px;
}

.map-styles-collapse .ant-page-header {
  padding: 0px 16px;
  background-color: var(--secondary-background);
  cursor: pointer;
}

.map-styles-collapse .ant-page-header:hover {
  background: var(--shade);
}

.map-styles-collapse .ant-page-header-heading .ant-avatar {
  border: 1px solid var(--default-grey);
}

.sorting-layer {
  z-index: 10000 !important;
}

.layer>.ant-collapse-header {
  height: 52px;
  align-items: center !important;
  justify-content: space-between;
  border-radius: 2px !important;
  padding: 8 !important;
  background-color: var(--light-blue);
}

.layer .ant-collapse-content>.ant-collapse-content-box {
  padding: 8px !important;
}

.layer>.ant-collapse-header:hover {
  background: var(--light-blue);
}

.layer .ant-typography {
  margin-top: 8px;
  color: var(--default-grey);
}

.layer svg {
  font-size: 12px;
}

.layer .ant-collapse-arrow {
  color: var(--shade);
}

.layer .ant-collapse-extra {
  margin: 0 !important;
}

.layer-properties-overlay::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.layer-properties-overlay {
  opacity: 0.5;
}

.layer-properties {
  position: relative;
}

.layer-properties .ant-collapse-header {
  padding: 0 !important;
  cursor: pointer !important;
  width: 100% !important;
}

.layer-properties .ant-collapse-header-text {
  width: 100% !important;
}

.layer-properties .ant-collapse-content>.ant-collapse-content-box {
  padding: 12px !important;
}

.layer-properties .config-name {
  /* border-left: 3px solid var(--default-grey) !important; */
  /* padding-left: 5px; */
  color: var(--default-grey) !important;
  font-size: 14px;
  margin: 0;
  line-height: 16px;
  font-family: Inter;
}

.layer-properties .label {
  color: var(--default-grey) !important;
  margin: 0 !important;
}

.layer-properties .ant-switch {
  z-index: 3;
}

.layer-properties .ant-switch,
.analytics .ant-switch,
.color-range-container .ant-switch,
.time-series-info .ant-switch,
.ant-switch-handle::before {
  border-radius: 0px !important;
}

.layer-properties .ant-switch,
.analytics .ant-switch,
.time-series-info .ant-switch,
.color-range-container .ant-switch {
  margin-bottom: 4px !important;
  background-color: var(--secondary-background) !important;
}

.layer-properties .ant-switch-checked,
.analytics .ant-switch-checked,
.time-series-info .ant-switch-checked,
.color-range-container .ant-switch-checked {
  background-color: var(--default-cta) !important;
}

.layer-properties .ant-switch-checked:focus,
.analytics .ant-switch-checked:focus,
.time-series-info .ant-switch-checked:focus,
.color-range-container .ant-switch-checked:focus {
  box-shadow: none !important;
}

.analytics .ant-switch-small {
  margin-bottom: 2.5px !important;
}

.color-range-container .ant-switch-small {
  margin: 0 !important;
}

.layer-properties .ant-input-number,
.filters .ant-input-number,
.time-series .ant-input-number {
  background-color: var(--light-shade) !important;
  border: 0px !important;
  color: var(--default-black) !important;
}

.layer-properties .ant-input-number-focused,
.filters .ant-input-number-focused,
.time-series .ant-input-number-focused {
  background: var(--shade) !important;
  box-shadow: none !important;
}

.layer-properties .ant-input-number-handler-wrap,
.filters .ant-input-number-handler-wrap,
.time-series .ant-input-number-handler-wrap {
  display: none !important;
}

.layer-properties svg {
  color: var(--default-grey);
  font-size: 24px;
  cursor: pointer;
}

.layer-properties .more-icon {
  margin-right: -10px;
}

.slider-container>.ant-space-item:first-child,
.select-filter-field>.ant-space-item:first-child,
.timeseries-filter-dropdown>.ant-space-item:first-child {
  width: 100% !important;
}

.layer-properties .ant-slider {
  width: 90% !important;
}

.layer-properties .ant-slider-handle:focus,
.time-series-slider .ant-slider-handle:focus,
.filters .ant-slider-handle:focus,
.time-series .ant-slider-handle:focus {
  box-shadow: 0 0 0 5px var(--light-shade) !important;
}

.layer-properties .ant-slider-step,
.time-series-slider .ant-slider-step,
.time-series .ant-slider-step {
  background-color: var(--secondary-background) !important;
  border-radius: 0px !important;
}

.layer-properties .ant-slider-track,
.time-series-slider .ant-slider-track,
.time-series-info .ant-slider-track:not(.time-series-range .ant-slider-track),
.filters .ant-slider-track,
.time-series .ant-slider-track {
  background-color: var(--default-cta);
  border: 0px !important;
  border-radius: 0px !important;
  z-index: 1;
}

.time-series-range .ant-slider-step {
  background-color: var(--light-shade) !important;
  border-radius: 0px !important;
  height: 15px;
}

.time-series-range .ant-slider-track {
  background-color: var(--default-cta) !important;
  border: 0px !important;
  border-radius: 0px !important;
  margin-top: 6px !important;
}

.time-series-range .ant-slider:hover .ant-slider-step {
  background-color: var(--light-shade) !important;
}

.time-series-range .ant-slider-handle {
  margin-top: -1.8px;
  height: 18px;
  width: 12px;
}

.time-series-range .ant-slider-dot-active {
  background-color: var(--default-cta) !important;
  margin-top: 2px !important;
  height: 15px !important;
}

.time-series-range .ant-slider-rail {
  background-color: var(--default-grey) !important;
  margin-top: 6px !important;
}

.time-series-range .ant-slider-dot {
  background-color: var(--default-cta);
  height: 8px;
  border: none;
  width: 2px;
  border-radius: 0px;
  margin-top: 5px;
}

.time-series-range .ant-tooltip-inner {
  padding: 8px 16px !important;
  background-color: var(--light-shade) !important;
}

.time-series-range .ant-tooltip-placement-top .ant-tooltip-arrow-content {
  background-color: var(--default-grey) !important;
}

.layer-properties .ant-slider-handle,
.time-series-slider .ant-slider-handle,
.time-series-info .ant-slider-handle,
.time-series .ant-slider-handle,
.filters .ant-slider-handle {
  border: 0px !important;
  border-radius: 0px !important;
  z-index: 1;
  background-color: var(--default-cta);
}

.layer-properties .ant-slider:hover .ant-slider-track,
.time-series-slider .ant-slider:hover .ant-slider-track,
.time-series .ant-slider:hover .ant-slider-track,
.filters .ant-slider:hover .ant-slider-track {
  background-color: var(--default-cta);
}

.layer-properties input[type="color"] {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  height: 30px;
  background: none;
  appearance: none;
}

.category-tab .ant-tabs-tab .ant-tabs-tab-btn,
.category-tab .ant-tabs-tab .ant-tabs-tab-btn button {
  color: var(--default-grey) !important;
}

.category-tab .ant-tabs-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--default-black) !important;
}

.category-tab .ant-tabs-tab-active .ant-tabs-tab-btn,
.category-tab .ant-tabs-tab-active .ant-tabs-tab-btn span,
.category-tab .ant-tabs-ink-bar {
  color: var(--default-black) !important;
  font-weight: 600;
}

.category-tab .ant-tabs-ink-bar {
  display: none;
}

.category-tab .ant-badge-count {
  background: var(--light-shade) !important;
}

.categories-container .ant-collapse-header {
  background-color: var(--layout-menu-selected-background-color);
}

.categories-container .ant-collapse-content-box {
  padding: 12px 0px !important;
}

.categories-container svg:not(.dataset-handling-icons svg) {
  color: var(--secondary-grey);
}

.dataset-handling-icons {
  z-index: 20000 !important;
  position: absolute !important;
  right: 40px;
  top: 0px;
  z-index: 100000 !important;
}

.categories {
  background-color: transparent !important;
  font-family: Inter;
}

.categories svg:not(.dataset-handling-icons svg) {
  font-size: 18px;
  color: var(--secondary-grey) !important;
}

.categories .ant-tree-iconEle {
  margin: 2.5px 2.5px 0px 0px !important;
  width: auto !important;
  height: auto !important;
}

.categories .ant-tree-treenode-selected::before {
  background-color: var(--secondary-background) !important;
  border-left: 3px solid var(--default-cta);
}

.categories .ant-tree-treenode:hover::before {
  background-color: var(--secondary-background) !important;
  border-left: 3px solid var(--default-cta);
}

.categories .ant-tree-treenode-selected .ant-typography,
.categories .ant-tree-treenode-selected svg:not(.dataset-handling-icons svg) {
  font-weight: 600;
}

.categories .ant-tree-switcher {
  background: transparent !important;
  /* margin-top: 2.5px !important; */
}

.categories .ant-tree-switcher-icon {
  display: none !important;
}

.ant-tabs-content {
  height: 100% !important;
}

.categories .ant-tree-node-content-wrapper:not(.ant-tree-node-content-wrapper-normal) {
  margin-left: -22px !important;
}

.categories .ant-tree-node-content-wrapper {
  display: flex;
  padding: 0 !important;
}

.categories .ant-tree-switcher-leaf-line::after {
  border-bottom: 1px solid var(--secondary-grey) !important;
}

.categories .ant-tree-switcher-leaf-line::before,
.categories .ant-tree-indent-unit::before {
  border-right: 1px solid var(--secondary-grey) !important;
}

.categories .ant-tree-checkbox {
  position: absolute;
  right: 4px;
  z-index: 2 !important;
}

.categories .ant-tree-checkbox-checked:hover::after {
  border: 0 !important;
}

.categories .ant-tree-checkbox-inner,
.assets .ant-checkbox-inner {
  border: 0.5px solid var(--default-black) !important;
}

.categories .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.assets .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--default-grey) !important;
  border-color: var(--default-grey) !important;
}

.categories-upload .ant-typography {
  color: var(--secondary-grey);
  font-size: 14px;
  margin: 0px;
  width: 215px;
}

.categories-upload .ant-typography .percentage-text {
  color: var(--default-cta) !important;
  font-size: 12px !important;
}

.categories-upload .ant-page-header-heading {
  flex-wrap: nowrap !important;
  width: 100%;
}

.filters svg:not(.ant-btn svg) {
  transition: 0.5s ease-out;
  color: var(--default-grey);
  cursor: pointer;
}

.filters svg:hover:not(.bar-plot svg) {
  color: var(--light-orange) !important;
  transition: 0.2s ease-in;
  transform: scale(1.1);
}

.filters {
  background-color: var(--dark-shade) !important;
  border-radius: 5px !important;
}

.filters .ant-collapse-header {
  background-color: var(--light-blue) !important;
}

.filters .ant-collapse-content {
  background-color: var(--light-blue) !important;
}

.filters .ant-collapse-arrow {
  top: 35% !important;
}

.filters .ant-slider-step {
  background-color: var(--light-shade) !important;
  border-radius: 0px !important;
}

.filters .ant-slider-handle-1::after {
  content: "";
  position: absolute;
  top: -65px;
  left: 5px;
  height: 35px;
  width: 4px;
  background-color: var(--shade);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: ew-resize;
}

.filters .ant-slider-track-1::before {
  content: "";
  position: absolute;
  top: -90px;
  height: 75px;
  width: 100%;
  background-color: var(--default-grey);
  opacity: 0.15;
  border-radius: 5px;
  cursor: move;
}

.filters .ant-slider-handle-2 {
  margin-right: 107px;
}

.filters .ant-slider-handle-2::before {
  content: "";
  position: absolute;
  top: -65px;
  right: 5px;
  height: 35px;
  width: 4px;
  background-color: var(--shade);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: ew-resize;
}

.analytics .btn-group,
.geospatial-filter .btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.analytics .btn-group .ant-btn.active,
.geospatial-filter .ant-btn.active {
  background-color: var(--default-cta);
  /* width: 4rem; */
}

.analytics .btn-group .ant-btn.deactive,
.geospatial-filter .ant-btn.deactive {
  background-color: var(--default-hover-color);
  /* width: 4rem; */
}

.analytics-card,
.geospatial-card {
  background-color: var(--light-blue) !important;
  border-radius: 2px !important;
  margin-top: 1rem !important;
  padding: 16px;
}

.analytics-feature-card {
  background-color: var(--dark-shade) !important;
  border-radius: 2px !important;
  /* overflow-y: scroll; */
  height: 100%;
  border: 1px solid var(--default-grey) !important;
}

.analytics-feature-card .ant-card-head-title {
  padding: 0px !important;
}

.analytics-feature-card .ant-card-extra {
  padding: 0px !important;
}

.analytics-feature-card .ant-card-actions {
  background-color: transparent !important;
  padding: 0 8px;
  border-top: 1px solid var(--default-grey);
}

.choose-layer {
  width: "100%";
  background-color: var(--secondary-background) !important;
  border: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.choose-layer>.ant-dropdown-menu-item {
  flex-grow: 1;
  padding: 10px 15px;
  color: var(--default-grey) !important;
}

.choose-layer>.ant-dropdown-menu-item:hover {
  background: var(--shade) !important;
  color: var(--default-white) !important;
}

.choose-layer svg {
  font-size: 42px;
  border: 2px solid var(--default-grey);
  color: var(--default-grey);
  padding: 5px;
}

.choose-layer svg:hover {
  color: var(--default-white) !important;
  border: 2px solid var(--default-white);
}

.select-fields {
  border: 0px;
  max-height: 350px;
  max-width: 100%;
  overflow-y: scroll;
}

.select-fields.layer {
  margin-left: 20px !important;
  width: 258px !important;
}

.select-fields.layer svg {
  color: var(--default-grey);
}

.select-fields .ant-dropdown-menu-item {
  padding: 10px 15px;
  font-size: 14px;
  color: var(--default-grey) !important;
}

.select-fields>.ant-dropdown-menu-item:hover {
  background: var(--light-blue) !important;
  color: var(--default-grey) !important;
}

.select-fields .ant-input-affix-wrapper {
  background-color: var(--light-grey) !important;
}

.select-fields .ant-input-affix-wrapper input {
  background-color: var(--light-grey) !important;
  color: var(--default-black) !important;
}

.select-fields-input {
  background-color: var(--secondary-background);
  width: 100%;
  height: 40px;
  color: var(--default-grey);
  font-size: 14px;
  cursor: pointer;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
}

.select-fields-input .ant-dropdown {
  margin-left: 36px !important;
  width: 258px !important;
}

.select-fields-input svg {
  font-size: 16px;
  margin-top: 4px;
  z-index: 1000;
}

.select-datasets {
  background-color: var(--dark-shade) !important;
  text-align: left !important;
  border: 0px !important;
  width: 205px !important;
  height: 40px !important;
  color: var(--default-grey) !important;
  font-size: 14px !important;
  margin-top: -2px !important;
}

.select-datasets .ant-typography {
  color: var(--default-grey) !important;
}

.select-datasets-overlay {
  background-color: var(--secondary-background) !important;
  border: 0px;
  max-height: 350px;
  width: 283px;
  overflow-y: scroll;
}

.select-datasets-overlay .ant-dropdown-menu-item {
  padding: 10px 15px;
  font-size: 14px;
  color: var(--default-grey) !important;
}

.select-datasets-overlay>.ant-dropdown-menu-item:hover {
  background: var(--shade) !important;
  color: var(--default-white) !important;
}

.select-datasets-overlay .ant-typography {
  color: var(--default-grey) !important;
}

.select-datasets-overlay .ant-input-affix-wrapper {
  background-color: var(--dark-shade) !important;
}

.select-datasets-overlay .ant-input-affix-wrapper input {
  background-color: var(--dark-shade) !important;
  color: var(--default-grey) !important;
}

.columnContainer .ant-typography {
  margin-top: 0px;
}

.columnTitle :nth-child(1) {
  float: left;
}

.columnTitle :nth-child(2) {
  float: right;
}

/* .controll .ant-drawer-body,
.controll .ant-tabs-content-holder {
  scrollbar-width: thin;
} */

.controll .ant-drawer-body::-webkit-scrollbar,
.controll .ant-tabs-content-holder::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

.controll .ant-drawer-body::-webkit-scrollbar-thumb,
.controll .ant-tabs-content-holder::-webkit-scrollbar-thumb {
  background-color: var(--secondary-background) !important;
  border-radius: 50px !important;
}

.select-fields {
  scrollbar-width: thin !important;
}

.select-fields::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  background-color: var(--secondary-background);
}

.select-fields::-webkit-scrollbar-thumb {
  background-color: var(--default-grey) !important;
  border-radius: 50px !important;
}

.data-item {
  display: block;
}

.data-table .ant-pagination.ant-pagination-mini .ant-pagination-next,
.ant-pagination.ant-pagination-mini .ant-pagination-prev {
  margin: 0 3px !important;
}

.data-table .ant-pagination-next button {
  color: var(--default-white);
}

.data-table .ant-pagination-prev button {
  color: var(--default-white);
}

.data-table .ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: var(--default-grey);
  /* background-color: var(--light-shade) !important; */
}

.data-table .ant-table-body {
  scrollbar-width: thin !important;
}

.data-table tr:nth-child(2n) td {
  background-color: var(--light-grey) !important;
}

.data-table .ant-table-pagination.ant-pagination {
  margin: 0 0 16px 0 !important;
}

.dataset-progress .ant-progress-inner {
  height: 2px;
  margin-top: -30px;
}

.dataset-table .ant-table-thead .ant-table-cell {
  border: none;
  background-color: var(--light-grey);
}

.dataset-table tr:nth-child(2n + 1) td {
  background-color: var(--light-grey) !important;
}

.uploaded-dataset .ant-progress-inner {
  height: 2px;
  margin-top: -42px !important;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  bottom: 0;
  z-index: 1002;
  width: 20px;
  height: 100%;
  cursor: col-resize;
}

.data-table .react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.react-resize-icon {
  position: absolute;
  /* top: 50vh; */
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-background);
  color: var(--default-white);
  border-radius: 50%;
  z-index: 1000;
  bottom: 150px;
}

.table-title {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.notification-list .ant-list-items {
  overflow-x: scroll !important;
  max-height: 350px !important;
}

.notification-list .ant-dropdown-menu-item:hover {
  pointer-events: none !important;
}

.object-info-popup .mapboxgl-popup-content {
  padding: 0 !important;
  cursor: pointer !important;
}

.object-info .ant-descriptions-item-content {
  padding: 4px !important;
}

.object-info .ant-card-actions>li {
  text-align: left !important;
}

.report-tab {
  padding: 0px 24px 12px 24px !important;
}

.report-tab .ant-tabs-nav {
  margin: 0px 0px 12px 0px !important;
}

.report-tab .ant-tabs-tabpane {
  min-height: 80vh !important;
}

.report-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.report-tab .ant-tabs-tab.ant-tabs-tab-active svg {
  color: var(--default-grey) !important;
}

.report-tab .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--default-white) !important;
}

.report-tab .ant-tabs-tab .ant-tabs-tab-btn,
.report-tab .ant-tabs-tab svg {
  color: var(--default-white) !important;
}

.report-tab .ant-tabs-tab {
  background-color: var(--default-grey) !important;
}

.colorRange-select {
  width: 50% !important;
}

.colorRange-select>.ant-select-selector {
  background-color: var(--layout-menu-selected-background-color) !important;
  color: var(--default-white) !important;
}

.report-charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 12px;
}

.report-card {
  min-width: 500px;
  height: min-content;
}

.report-card .ant-typography-edit {
  display: inline-block !important;
  margin-left: 24px;
  color: var(--default-black);
}

.report-card .ant-typography-edit-content-confirm {
  display: inline-block !important;
}

.dropdown-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.export-options {
  background-color: var(--secondary-background) !important;
}

.export-options .ant-dropdown-menu-item {
  padding: 10px 15px;
  font-size: 14px;
  color: var(--default-grey) !important;
}

.export-options>.ant-dropdown-menu-item:hover {
  background: var(--shade) !important;
  color: var(--default-white) !important;
}

.legend-items {
  width: "100%";
  white-space: nowrap;
  font-weight: 300;
  color: var(--shade);
}

.legend-items.ant-descriptions-item,
.legend-items.ant-descriptions-item {
  padding: 4px !important;
  width: 225px !important;
  overflow-x: scroll;
}

.legend-items.ant-descriptions-item-label {
  font-size: 14px !important;
  color: var(--default-grey) !important;
}

.legend-items.ant-descriptions-item-content {
  font-size: 14px !important;
  color: var(--default-grey) !important;
}

.legend-items>span {
  font-weight: 600;
  color: var(--default-background);
}

.categories-search,
.categories-search input {
  background-color: var(--secondary-background) !important;
  border-color: var(--secondary-background) !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}

.categories-search svg {
  color: var(--default-black);
}

.categories-search .anticon-close-circle {
  margin-right: 12px;
}

.dataset-info .ant-card-head-title,
.legend .ant-card-head-title {
  padding: 7px 0 !important;
}

.dataset-info .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 7px 12px !important;
}

.dataset-info-card-header>.ant-space-item:first-child,
.legend-card-header>.ant-space-item:first-child {
  flex: 1;
}

.input-hide-geometry>span:not(.ant-checkbox) {
  padding-inline: 2px;
}

.dataset-info-paragraph>a {
  display: block !important;
}

.categories .ant-tree-iconEle>.anticon-file {
  display: none !important;
}

.categories .ant-tree-iconEle>.anticon-folder,
.categories .ant-tree-iconEle>.anticon-folder-open {
  padding: 0 4px;
}

.controll-header {
  background: transparent;
  width: 100%;
  color: var(--default-white) !important;
  display: flex;
  margin-top: 4px !important;
  margin-bottom: 12px !important;
  padding: 12px 16px 0 !important;
}

.controll-header .ant-space-item:first-of-type {
  flex: 1 !important;
}

.controll-header .ant-input-affix-wrapper {
  background: none;
  border: none;
  outline: none;
  color: var(--default-grey);
}

.controll-header .ant-input-affix-wrapper input {
  background: none;
  color: var(--default-white);
  font-size: 15px;
  line-height: 18px;
  height: 24px;
  caret-color: var(--default-grey);
}

.controll-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--default-background) !important;
}

.controll-tabs .ant-tabs-ink-bar {
  display: none;
}

.controll-tabs>.ant-tabs-nav .ant-tabs-tab {
  justify-content: center;
}

.controll-tabs>.ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn .anticon {
  margin-right: 0 !important;
}

.controll-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--default-white) !important;
  color: var(--primary-background) !important;
  border: none !important;
  border-radius: 30px;
  padding: 4px 12px;
  margin: 2px;
}

.controll-tabs .ant-tabs-tab-btn {
  color: var(--default-white) !important;
}

.controll-tabs .ant-tabs-nav-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.controll-tabs .ant-tabs-tab:not(.category-tab.main .ant-tabs-tab) {
  border: none !important;
  border-radius: 30px;
  padding: 0 15px;
}

.controll-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 2px 0 2px -5px !important;
}

.category-tab .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--default-white) !important;
  border: none !important;
  border-radius: 0%;
  padding: 0 !important;
}

.category-tab .ant-tabs-nav-list {
  width: 96%;
  display: flex;
  padding: 0px;
}

.category-tab.consultation .ant-tabs-nav-list {
  width: 90%;
  display: flex;
  padding: 0px;
  justify-content: center;
}

.category-tab .ant-tabs-tab-active svg {
  color: var(--default-background) !important;
  fill: var(--default-background) !important;
}

.ant-tabs-nav::before {
  border-bottom: none !important;
}

/* access components styles */

.ant-page-header .ant-breadcrumb-link {
  font-weight: 500;
  font-size: medium;
}

.access-page-sider,
.reports-page-sider {
  flex: 0 0 20vw !important;
  max-width: 20vw !important;
  min-width: 240px !important;
  width: 20vw !important;
  background: none;
}

.access-sidebar {
  background: var(--default-white);
  height: 100vh;
  width: 20vw;
  min-width: 240px;
  row-gap: 0 !important;
}

.access-sidebar>.ant-space-item:first-child {
  height: 10vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  border-radius: 20px;
}

.access-sidebar .ant-menu-item {
  color: var(--light-shade);
  height: 2.5rem;
  font-size: 1rem !important;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  margin: 0;
}

.access-sidebar .ant-menu-item .ant-menu-title-content {
  width: 98%;
  display: flex;
  justify-content: space-between;
}

.access-sidebar .ant-menu-item-selected {
  background-color: var(--light-grey) !important;
  color: var(--dark-shade);
  font-weight: 600;
  border-right: 4px solid var(--default-blue);
  position: relative;
}

.access-sidebar .ant-badge {
  position: absolute;
  left: max(20vw - 50px, 190px);
}

.access-sidebar .ant-badge-count {
  border-radius: 4px !important;
  width: 1.4rem !important;
  height: 1.4rem !important;
  font-size: 0.9rem;
  padding: 0;
}

.access-tab .ant-tabs-tabpane {
  padding: 0 !important;
}

.access .input-container,
.revoke-access-modal .input-container,
.report-share-modal .input-container {
  width: 100%;
  background-color: var(--light-grey);
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

.access_menu_permissions_dropdown>button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}

.input-container.wrap {
  gap: 0;
}

.invite-la-select {
  width: 100% !important;
  margin: 16px 0px !important;
}

.invite-la-select .ant-select-selector {
  background-color: var(--light-grey) !important;
  border: 1px solid var(--default-hover-color) !important;
  min-height: 50px !important;
  border-radius: 4px !important;
  border-color: none !important;
  box-shadow: none !important;
}

.access .input-container input,
.access .input-container textarea,
.access .ant-select,
.revoke-access-modal .input-container input,
.revoke-access-modal .input-container textarea,
.report-share-modal .input-container input,
.report-share-modal .input-container textarea {
  background: inherit !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.ant-table-wrapper.manage-access-table thead th {
  background: var(--default-cta);
}

/* .ant-table-wrapper.manage-access-table thead th:hover {
  background: #058458;
} */

.manage-access-table .ant-table-thead .ant-table-cell {
  background-color: var(--default-white);
}

.manage-access-table thead th:last-child {
  display: none;
}

.manage-access-table.user-upload-list thead th:last-child {
  display: block !important;
}

.manage-access-table thead th:nth-last-child(2) {
  text-align: left;
}

.manage-access-table table .ant-table-cell {
  padding-inline: 1rem !important;
  font-weight: 500;
  border: none;
}

/* Access page classes */

/* .guidance-tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  border-bottom: 1px solid var(--primary-background);
} */

.access-tabs .ant-tabs-nav-list {
  color: var(--default-white);
  border: 1px solid var(--light-grey);
  border-radius: 25px;
}

.access-tabs .ant-tabs-tab {
  padding: 10px 30px;
  margin: 2px !important;
  background-color: transparent;
  border-radius: 20px;
}

.access-tabs .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.access-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--dark-shade) !important;
}

.access-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--default-white) !important;
}

.access-tabs .ant-tabs-tab:hover {
  color: unset;
}

.access-tabs .ant-tabs-ink-bar {
  background: none !important;
}

.access-tabs .ant-tabs-nav::before {
  border-bottom: none !important;
}

.custom-active-tab {
  background: var(--default-white) !important;
  padding: 0px;
  width: 520px;
  border-radius: 40px;
  margin-bottom: 20px;
}

.custom-active-tab .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--primary-background) !important;
  border: none;
}

.custom-active-tab .ant-tabs-tab.ant-tabs-tab-active>.ant-tabs-tab-btn {
  color: white !important;
  border: none;
}

.custom-active-tab .ant-tabs-tab:not(.ant-tabs-tab-active) .ant-tabs-tab-btn {
  color: black !important;
  border: none;
}

.access-breadcrumb {
  cursor: pointer;
  font-size: 22px;
}

.manage-access-main-page {
  background-color: var(--primary-background);
  margin: 5px 5px 5px 0px;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 99.5%;
  flex-wrap: wrap;
  gap: 12px;
}

.manage-access-card {
  padding: 1rem 1.5rem;
  height: 63vh;
}

.ant-btn-primary.primary-btn,
.ant-btn-primary.primary-btn:hover,
.ant-btn-primary.primary-btn:focus {
  background-color: var(--default-cta);
  transition: all 0.4s ease 0s;
  border: none;
}

.manage-btn {
  background: none !important;
  line-height: 1rem !important;
  height: auto !important;
}

.permission-group>.ant-space-item:nth-child(2) {
  flex: 1;
}

.permission-group .ant-page-header-content {
  padding: 0;
}

.custom-permissions>*,
.base-permissions>* {
  flex-basis: 100%;
  height: 100%;
}

.base-permissions .info-card {
  padding: 1rem 1.5rem;
  margin-top: 4rem;
  background-color: var(--secondary-green);
  border-top: 8px solid var(--default-blue);
  border-radius: 5px;
}

/*.access-picker {
   position: absolute;
  top: 0;
  right: 40px; 
}*/

.access-picker-dropdown {
  width: 135px !important;
}

.datasets .ant-card-head-wrapper {
  height: 48px;
}

.datasets .ant-card-head-title .ant-typography,
.datasets .ant-card-extra .ant-typography {
  color: var(--default-white) !important;
}

.datasets>* {
  padding: 0.5rem 1rem;
}

.datasets {
  width: 33vw;
}

.datasets-header {
  background: var(--default-cta);
  display: flex;
  justify-content: space-between;
}

.datasets-header>* {
  color: var(--default-white);
  font-size: 1rem;
  font-weight: 500;
}

.datasets-header .access-picker .ant-select-selector {
  color: var(--default-white) !important;
}

.datasets-header .access-picker .ant-select-selection-item {
  font-weight: 500;
  font-size: 1rem;
}

.datasets-header .access-picker .ant-select-arrow {
  color: var(--default-white);
}

.datasets-tree svg:not(.dataset-handling-icons svg):not(.access-picker svg) {
  font-size: 18px;
  color: var(--secondary-grey) !important;
}

.datasets-tree .ant-tree-iconEle {
  margin: 2.5px 2.5px 0px 0px !important;
  width: auto !important;
  height: auto !important;
}

.datasets-tree .ant-tree-title {
  width: 100%;
}

.datasets-tree .ant-tree-indent {
  margin-left: 24px !important;
}

.datasets-tree .ant-tree-treenode-selected::before {
  background-color: var(--default-white) !important;
  color: var(--default-grey) !important;
}

.datasets-tree .ant-tree-treenode:hover::before {
  background-color: var(--default-white) !important;
}

.datasets-tree .ant-tree-switcher {
  background: transparent !important;
}

.datasets-tree .ant-tree-switcher-icon {
  display: none !important;
}

.datasets-tree .ant-tree-node-content-wrapper:not(.ant-tree-node-content-wrapper-normal) {
  margin-left: -22px !important;
}

.datasets-tree .ant-tree-node-content-wrapper {
  display: flex;
  padding: 0 !important;
  align-items: center;
  min-height: 38px !important;
  height: 100% !important;
}

.datasets-tree .ant-tree-switcher-leaf-line::after {
  border-bottom: 1px solid var(--secondary-grey) !important;
}

.datasets-tree .ant-tree-switcher-leaf-line::before,
.datasets-tree .ant-tree-indent-unit::before {
  border-right: 1px solid var(--secondary-grey) !important;
}

/* access directorey tree */
.datasets-tree .ant-tree-switcher-icon {
  display: none !important;
}

.datasets-tree .ant-tree-switcher-leaf-line::after {
  border-bottom: 1px solid var(--secondary-grey) !important;
}

.datasets-tree .ant-tree-switcher-leaf-line::before,
.datasets-tree .ant-tree-indent-unit::before {
  border-right: 1px solid var(--secondary-grey) !important;
}

.datasets-tree .ant-tree-checkbox {
  position: absolute;
  left: 0px;
  margin-top: 10px;
  z-index: 2 !important;
}

.datasets-tree .ant-tree-checkbox-inner {
  border: 1px solid var(--default-grey);
  padding: 8px;
}

/* download progress style */
.progress-bar {
  height: 2.5px;
  margin-top: -4px;
  /* background-color: rgba(5, 114, 206, 0.2); */
  background-color: var(--light-text);
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: var(--default-blue);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 60%;
}

.modal-option-alert .ant-alert-content {
  margin-top: -5px;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }

  40% {
    transform: translateX(0) scaleX(0.5);
  }

  100% {
    transform: translateX(100%) scaleX(1);
  }
}

/* dfes styles */
.dfes-container .heading {
  margin-bottom: 20px;
}

.dfes-container .switch-wrapper {
  margin-block: 20px;
}

.dfes-switch {
  width: max-content;
  display: inline-flex;
  border: 1px solid var(--default-grey);
  border-radius: 9999px;
  box-shadow: 0px 1px 2px var(--default-black);
}

.dfes-switch input[type="radio"] {
  display: none;
}

.dfes-switch input[type="radio"]:checked+label {
  background-color: var(--default-cta);
  color: var(--default-white);
}

.dfes-switch label {
  color: var(--default-grey);
  padding: 2px 16px;
  border-radius: 9999px;
  cursor: pointer;
}

.dfes-container .dropdown-wrapper {
  margin-block: 20px;
}

.dfes-dropdown {
  display: block;
  width: 100%;
  margin-block: 10px;
}

.dfes-dropdown .ant-select-item-option-content {
  display: flex !important;
  white-space: unset;
}

.dfes-dropdown-overlay {
  opacity: 0.3;
}

/* .dfes-dropdown-overlay::before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
} */

.dfes-dropdown .ant-select-selector {
  background-color: var(--secondary-background) !important;
  height: 40px !important;
  display: flex;
  align-items: center;
}

.dfes-dropdown .ant-select-arrow {
  color: var(--default-grey) !important;
}

.dfes-dropdown .ant-select-dropdown {
  background-color: var(--light-blue) !important;
}

/* .dfes-dropdown .ant-select-item {
  color: rgba(255, 255, 255, 0.65) !important;
} */

.dfes-dropdown .ant-select-item-option-selected,
.dfes-dropdown .ant-select-item-option-active {
  background-color: var(--default-white) !important;
  /* color: white !important; */
}

.scenario-tabs .ant-tabs-nav {
  margin: 0px !important;
}

.scenario-map-chartseries {
  position: absolute !important;
  background-color: var(--default-white) !important;
  bottom: 205px;
  right: 30px;
  width: 100px !important;
  color: var(--default-black);
}

.scenario-map-series {
  position: absolute !important;
  background-color: var(--primary-background) !important;
  height: 90px;
  color: var(--default-black);
  padding-left: 30px;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  width: 74% !important;
  margin-right: 26% !important;
  bottom: 0px;
  margin-bottom: 0% !important;
  right: 0px;
}

.dfes-map-series {
  position: absolute !important;
  background-color: var(--primary-background) !important;
  height: 90px;
  color: var(--default-black);
  padding-left: 30px;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  /* width: 70% !important; */
  /* width: calc(100% - 450px) !important; */
  /* width: 100% !important; */
  margin-right: 0px !important;
  bottom: 0px;
  margin-bottom: 0% !important;
  right: 0px;
}

.new-scenario-map-series {
  position: absolute !important;
  background-color: var(--primary-background) !important;
  height: 90px;
  color: var(--default-black);
  padding-left: 30px;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  /* bottom: 35px; */
  width: 100% !important;
  bottom: 0px;
  /* margin-bottom: 4% !important; */
}

.dfes-scenario-map-series {
  /* width: 1055px !important; */
  width: calc(100% - 8px) !important;
  position: absolute !important;
  background-color: var(--primary-background) !important;
  bottom: 10px;
  height: 90px;
  color: var(--default-black);
  padding-left: 30px;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  right: 172px;
  left: 3px;
}

.new-dfes-scenario-map-series {
  /* width: 1055px !important; */
  width: calc(100% - 8px) !important;
  position: absolute !important;
  background-color: var(--primary-background) !important;
  bottom: 10px;
  height: 90px;
  color: var(--default-black);
  padding-left: 30px;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  right: 172px;
  left: 3px;
}

.scenario-map-series-slider .ant-slider-step {
  background-color: var(--light-shade) !important;
  border-radius: 0px !important;
  height: 22px;
}

.scenario-map-series-slider {
  width: 80% !important;
  margin: 0;
  color: var(--default-black);
}

.scenario-map-series-slider .ant-slider-rail {
  background-color: var(--default-white) !important;
  margin-top: 8px !important;
  height: 6px;
}

.scenario-map-series-slider .ant-slider-step {
  background-color: var(--light-shade) !important;
  border-radius: 0px !important;
  height: 22px;
}

.scenario-map-series-slider .ant-slider-track {
  background-color: var(--default-cta) !important;
  border: 0px !important;
  border-radius: 0px !important;
  margin-top: 8px !important;
  height: 6px;
}

.ssen-scenario-map-series-slider .ant-slider-track {
  background-color: var(--light-shade) !important;
}

.scenario-map-series-slider .ant-slider:hover .ant-slider-step {
  background-color: var(--light-shade) !important;
}

.scenario-map-series-slider .ant-slider-handle {
  margin-top: -1.8px;
  height: 26px;
  width: 8px;
  border-radius: 0px;
  border: none;
  background-color: var(--default-cta);
}

.scenario-map-series-slider .ant-slider-dot-active {
  background-color: var(--default-cta) !important;
  margin-top: 2px !important;
  height: 22px !important;
}

.scenario-map-series-slider .ant-slider-dot {
  background-color: var(--default-white);
  height: 22px;
  border: none;
  width: 2px;
  border-radius: 0px;
  margin-top: 2.2px;
}

.scenario-map-series-slider .ant-tooltip-inner {
  background-color: var(--default-black) !important;
  color: var(--default-white) !important;
  font-size: 18px;
  font-weight: 600;
  padding: 0px 4px;
  display: flex;
  align-items: center;
}

.scenario-map-series-slider .ant-tooltip-arrow-content {
  background-color: var(--default-white) !important;
}

.scenario-map-series-slider .ant-slider-mark-text {
  color: var(--default-white) !important;
}

.scenario-charts-open {
  margin-bottom: 27%;
}

.scenario-charts-close {
  margin-bottom: 9%;
}

.dfes-timeseries {
  position: absolute !important;
  background-color: var(--default-white) !important;
  bottom: 0px;
  height: 200px;
  width: calc(100% - 390px) !important;
  color: var(--default-black);
}

.dfes-timeseries-slider .ant-slider-step {
  background-color: var(--light-shade) !important;
  border-radius: 0px !important;
  height: 22px;
}

.dfes-timeseries-slider .ant-slider-track {
  background-color: var(--default-cta) !important;
  border: 0px !important;
  border-radius: 0px !important;
  margin-top: 8px !important;
  height: 6px;
}

.dfes-timeseries-slider .ant-slider:hover .ant-slider-step {
  background-color: var(--light-shade) !important;
}

.dfes-timeseries-slider .ant-slider-handle {
  margin-top: -1.8px;
  height: 26px;
  width: 8px;
  border-radius: 0px;
  border: none;
  background-color: var(--default-cta);
}

.dfes-timeseries-slider .ant-slider-dot-active {
  background-color: var(--default-cta) !important;
  margin-top: 2px !important;
  height: 22px !important;
}

.dfes-timeseries-slider .ant-slider-rail {
  background-color: var(--default-grey) !important;
  margin-top: 8px !important;
  height: 6px;
}

.dfes-timeseries-slider .ant-slider-dot {
  background-color: var(--default-black);
  height: 22px;
  border: none;
  width: 2px;
  border-radius: 0px;
  margin-top: 2.2px;
}

.dfes-timeseries-slider {
  width: 90%;
  margin: 0;
  color: var(--default-black);
}

.dfes-timeseries-slider .ant-tooltip-inner {
  background-color: var(--default-white) !important;
  color: var(--default-black) !important;
  font-size: 18px;
  font-weight: 600;
  padding: 0px 4px;
  display: flex;
  align-items: center;
}

.dfes-timeseries-slider .ant-tooltip-arrow-content {
  background-color: var(--default-white) !important;
}

.dfes-speed-controls .ant-dropdown-menu-item:hover {
  background-color: var(--secondary-background) !important;
}

.dfes-icon {
  color: var(--default-background);
  font-size: 14px;
}

.dfes-icon:hover,
.ant-tabs-tab-active .dfes-icon {
  color: var(--default-white);
}

/* New reports */

.reports-page-header .ant-page-header-heading-left {
  flex-direction: column;
  align-items: flex-start !important;
}

.reports-page-header .ant-page-header-heading-sub-title {
  width: 550px;
  overflow: visible !important;
  white-space: normal !important;
  text-overflow: unset !important;
}

.reports-page-sider {
  min-width: 330px !important;
}

.reports-sidebar {
  background: var(--default-white);
  width: 100%;
  height: 100%;
}

.reports-search-input {
  background: var(--light-blue);
  margin: 14px;
  height: 40px;
  padding-left: 5px;
  padding-right: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.reports-search-input .ant-input {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--default-black) !important;
}

.pdf-doc-style {
  padding: 50px 30px;
}

.pdf-doc-style-laep {
  padding: 0 50px;
}

.pdf-doc-style>div,
pdf-doc-style-laep>div {
  display: none;
}

.reports-menu-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px !important;
  background-color: var(--light-blue);
  height: 100%;
  position: relative;
  transition: 300ms all ease;
}

.reports-sidebar .ant-menu-item-selected .reports-menu-label,
.reports-sidebar .ant-menu-item-active .reports-menu-label {
  background: var(--light-blue) !important;
}

.reports-sidebar .ant-menu-item .selected {
  display: none;
  transition: 300ms all ease;
}

.reports-sidebar .ant-menu-item-selected .selected {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background: var(--primary-background);
}

.report-share-options .ant-dropdown-menu-item,
.report-edit-options .ant-dropdown-menu-item {
  font-size: 14px;
  color: var(--default-black) !important;
}

.report-share-options,
.report-edit-options {
  background-color: var(--default-white) !important;
}

.report-share-options>.ant-dropdown-menu-item:hover,
.report-edit-options>.ant-dropdown-menu-item:hover {
  background: var(--light-orange) !important;
  color: var(--default-lightgrey) !important;
}

.report-share-modal .ant-form-item {
  margin-bottom: 0 !important;
}

.assets-guide .ant-timeline-item-head,
.assets-analysis .fix .ant-timeline-item-head {
  background-color: var(--default-cta) !important;
  border: 1px solid var(--default-cta);
}

.assets-analysis .warning .ant-timeline-item-head {
  background-color: var(--default-cta) !important;
  border: 1px solid var(--default-cta);
}

.assets-analysis .error .ant-timeline-item-head {
  background-color: var(--default-red) !important;
  border: 1px solid var(--default-red);
}

.assets-guide .ant-timeline-item-tail,
.assets-analysis .ant-timeline-item-tail {
  border-left: 2px solid var(--default-grey);
}

.assets .ant-descriptions-bordered .ant-descriptions-view,
.assets .ant-descriptions-bordered .ant-descriptions-row,
.assets .ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  border: none !important;
}

.assets .ant-empty-img-simple-ellipse,
.category-tab .ant-empty-img-simple-ellipse {
  fill: var(--light-shade);
}

.assets .ant-empty-img-simple-path,
.category-tab .ant-empty-img-simple-path {
  fill: var(--default-background);
}

.assets-header .ant-page-header-heading {
  align-items: center;
}

.assets-header .ant-page-header-heading-left {
  margin: 0px;
}

.assets .ant-popover-inner-content {
  padding: 8px;
}

.assets-wrapper>.ant-tabs-nav {
  display: none !important;
}

.assets-wrapper>.ant-tabs-content-holder {
  padding: 0px !important;
}

.assets .project-title textarea {
  background-color: var(--default-background);
  color: var(--default-white);
  margin-left: 12px;
}

.project-network-selector .ant-page-header-heading-extra {
  display: flex !important;
  align-items: center !important;
}

.project-network-selector .ant-segmented-item-selected {
  background-color: var(--default-cta) !important;
  color: var(--default-white) !important;
}

.project-network-selector .ant-page-header-heading {
  display: flex;
}

.asset-properties .ant-card-body,
.asset-properties .ant-card-head,
.asset-properties .ant-card-actions {
  background-color: var(--secondary-background) !important;
}

.asset-properties .ant-card-head {
  min-height: 34px !important;
  padding: 0px 12px;
  display: flex;
  align-items: center;
}

.asset-properties .ant-card-head-title {
  padding: 0px;
  color: var(--light-text);
}

.asset-properties .ant-card-actions {
  border: none;
}

.asset-properties .ant-card-actions>li {
  margin: 0px;
  padding: 0px 12px 8px 12px !important;
}

.asset-properties .ant-radio-group {
  display: flex !important;
  gap: 12px;
}

.asset-properties .ant-radio-button-wrapper {
  border: none !important;
}

.asset-properties .ant-radio-button-wrapper::before {
  background-color: transparent !important;
}

.asset-properties .ant-radio-button-wrapper-checked {
  background-color: var(--default-cta) !important;
  font-weight: 600;
}

.asset-properties .ant-radio-button .ant-radio-button-checked:focus-visible {
  border: none !important;
  outline: none !important;
}

.asset-properties .ant-radio-button-wrapper-checked:hover::before {
  border-color: transparent !important;
}

.asset-properties .ant-slider-track {
  background-color: var(--default-cta) !important;
}

.asset-properties .ant-slider-dot,
.asset-properties .ant-slider-dot-active {
  display: none !important;
}

.asset-properties .ant-slider-handle {
  border-radius: 0px;
  outline: none;
  border: none;
  width: 8px;
  background-color: var(--default-cta);
}

.asset-properties .ant-slider-rail {
  height: 2px;
  margin-top: 1px;
  background-color: var(--default-grey);
}

.asset-slider .ant-tooltip-placement-top {
  padding-bottom: 4px !important;
}

.asset-slider .ant-tooltip-inner {
  padding: 2px 6px !important;
  font-size: 11px;
  min-height: 24px;
  background-color: var(--default-cta);
}

.building-info .ant-popover-inner-content {
  padding: 8px;
  max-height: 70vh;
  overflow-y: scroll;
}

.building-info .ant-collapse-content {
  max-height: 350px !important;
  overflow-y: scroll !important;
}

.building-info .ant-collapse-content-box {
  padding: 0px !important;
}

.building-wrapper .ant-collapse-header {
  padding: 8px !important;
}

.building-address .ant-collapse-header {
  padding: 12px 0px !important;
}

.domestic-dropdown .domestic-asset-option {
  padding: 4px;
}

.domestic-dropdown .domestic-asset-option:hover {
  background-color: var(--light-blue) !important;
}

.asset-radio-options .ant-radio-button-wrapper {
  height: 25px !important;
  padding: 0 8px !important;
  font-size: 12px !important;
  line-height: 25px !important;
}

/* stories styles */

.data-stories-header .ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}

.meta-data-modal-tag>span {
  border-radius: 20px;
  color: black;
}

.sun-editor button {
  color: var(--default-grey) !important;
}

.sun-editor .se-svg,
.sun-editor button>svg {
  width: 14px !important;
  height: 14px !important;
}

.story-nav {
  height: 8vh;
  width: 100%;
  padding: 0px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.story-dots {
  padding: 8px !important;
  background-color: var(--default-cta);
  margin: auto !important;
  margin-left: 0px;
  margin-right: 0px;
  width: fit-content;
  bottom: -2% !important;
  position: absolute;
}

.story-dots {
  pointer-events: none !important;
}

.story-dots.has-map {
  right: -100% !important;
}

.story-dots-info {
  padding: 8px !important;
  background-color: var(--default-cta);
  margin: auto !important;
  margin-left: 0px;
  margin-right: 0px;
  width: fit-content;
  bottom: -4% !important;
  position: absolute;
}

.story-collapse {
  background-color: var(--default-white) !important;
}

.story-collapse .ant-collapse-content-box {
  padding: 0 !important;
}

.ant-carousel .slick-dots li.slick-active {
  width: 16px !important;
}

.story-section .slick-track {
  height: 100% !important;
}

.stories-alert .ant-alert-icon {
  margin-top: 4px !important;
}

/*---------- Story content area and content styles --------*/

.story-area {
  height: 71vh;
  padding: 16px;
  background-color: var(--default-white);
  display: flex !important;
  flex-direction: column;
  user-select: none;
  overflow-y: scroll;
  position: relative;
}

.stories_form>div>div>label {
  height: auto !important;
  margin-bottom: 16px !important;
}

@media (min-height: 200px) and (max-height: 900px) {

  /* Styles for when the browser window height is between 400 and 900 pixels */
  .story-area h1 {
    font-size: 1.5rem !important;
  }

  .story-area h2 {
    font-size: 1.4rem !important;
  }

  .story-area h3 {
    font-size: 1.2rem !important;
  }

  .story-area h4 {
    font-size: 1rem !important;
  }

  .story-area h5 {
    font-size: 0.8rem !important;
  }

  .scenario-consultation>h3 {
    font-size: 0.9rem !important;
    font-weight: 600 !important;
  }
}

.story-area.content-start {
  justify-content: flex-start;
}

.story-area.content-center {
  justify-content: center;
}

.story-content-area {
  width: 65%;
  margin: 0 auto;
}

.story-content-area.has-map {
  width: 90%;
}

.story-content-area img {
  max-width: 100%;
}

.scroll-indicate-btn {
  position: sticky !important;
  bottom: 0 !important;
  width: fit-content !important;
  margin: 0 auto !important;
  background-color: var(--default-cta) !important;
  border: none !important;
  animation: bounce 2s ease infinite;
}

@keyframes bounce {
  70% {
    transform: translateY(0%);
  }

  80% {
    transform: translateY(-15%);
  }

  90% {
    transform: translateY(0%);
  }

  95% {
    transform: translateY(-7%);
  }

  97% {
    transform: translateY(0%);
  }

  99% {
    transform: translateY(-3%);
  }

  100% {
    transform: translateY(0);
  }
}

.story {
  max-height: 76vh;
  padding: 16px 24px;
  background-color: var(--default-white);
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  scroll-snap-align: start;
  user-select: none;
}

.story-content {
  text-align: left;
  padding: 2px;
  overflow-y: scroll;
}

.media-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-section .ant-tabs-nav {
  display: none !important;
}

.media-section .ant-tabs-content-holder {
  padding: 16px 24px;
}

/* stories editor styles */

.stories-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.stories-editor .ant-card-head-wrapper {
  align-items: flex-start;
}

.stories-editor-content {
  width: 100%;
  padding: 12px;
}

.stories-container {
  display: grid;
  grid-template-columns: 16% 82%;
  gap: 2%;
}

.stories-editor-container,
.stories-reorder-container {
  height: 86.5vh;
  overflow-y: scroll;
  padding-right: 10px;
}

.stories-editor-content .story-media-card {
  min-height: 78vh !important;
}

.stories-editor-content .story-media-card .ant-card-head {
  min-height: 41.5px !important;
  padding: 0px 16px;
}

.stories-editor-content .story-media-card .ant-card-head-title {
  padding: 8px 0px;
}

.story-category .ant-tree.ant-tree-directory.ant-tree-treenode-selected:hover::before,
.story-category .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background-color: var(--default-cta) !important;
}

.story-category-rule .ant-tree-treenode-selected,
.story-category-rule .ant-tree-node-selected,
.story-category-rule .ant-tree-treenode:hover {
  background-color: var(--light-blue) !important;
}

.story-category-rule .ant-tree-treenode {
  padding: 0px !important;
  margin: 0 0 4px;
}

.story-category-rule .ant-tree-indent-unit {
  background-color: white;
}

/* object info additional css */

.object-info .ant-descriptions-row {
  display: flex !important;
}

/* Carbon panel styles */
.carbon-drawer .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}

.carbon-drawer .ant-drawer-content-wrapper {
  width: 45%;
}

.category-tab .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 15px;
}

.carbon-panel-tab .ant-tabs-tab .ant-tabs-tab-btn,
.carbon-panel-tab .ant-tabs-tab .ant-tabs-tab-btn button {
  color: var(--default-grey) !important;
}

.carbon-panel-tab .ant-tabs-tab-active .ant-tabs-tab-btn,
.carbon-panel-tab .ant-tabs-tab-active .ant-tabs-tab-btn span,
.carbon-panel-tab .ant-tabs-ink-bar {
  color: var(--default-background) !important;
}

.carbon-panel-tab .ant-tabs-ink-bar {
  background-color: var(--default-cta) !important;
}

.carbon-description .ant-descriptions-header {
  margin: 20px 0 0 0;
  background-color: var(--default-grey);
  padding-left: 15px;
}

.carbon-description .ant-descriptions-view {
  height: 80px;
  width: 100%;
  overflow-y: scroll;
}

.Carbon-table .ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
  padding: 10px 50px 10px 35px;
  border: 1px solid var(--light-text);
}

.Carbon-asset-table .ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
  padding: 10px 20px;
}

.project-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--default-cta);
  border: none;
}

.project-radio .ant-radio-checked .ant-radio-inner {
  border-color: var(--default-cta);
}

.project-radio .ant-radio-inner::after {
  background-color: var(--default-cta);
}

.dashboard-page-header .ant-page-header-heading {
  justify-content: flex-start;
}

.display-entity-card .ant-card-meta-detail {
  width: 100% !important;
}

.display-entity-card {
  width: 95%;
  height: 250px;
  cursor: pointer;
}

.display-entity-card .ant-card-meta-title {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

/* stakeholders engagement style */
.stakeholder-la-tree .ant-tree-switcher-noop {
  display: none;
}

.stakeholder-la-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: var(--default-cta);
  border: none;
}

.create-consultation .ant-form-item-label>label {
  width: 100% !important;
}

.story-section.stakeholder {
  height: 91vh !important;
}

.story-nav.stakeholder {
  height: 6vh;
}

/* Progress tracker */

.progress-tracker-side-boxes {
  height: 100%;
  width: 25%;
  padding: 20px;
  padding-top: 50px;
}

.progress-tracker-left-page {
  background-color: var(--default-white);
  padding-top: 40px;
  padding-left: 20px;
  width: 75%;
}

.home-progress-tracker .ant-steps-item-tail::after {
  width: 1px !important;
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.home-progress-tracker.no .ant-steps-icon-dot {
  background: var(--secondary-background) !important;
}

.home-progress-tracker.amber .ant-steps-icon-dot {
  background: var(--default-cta) !important;
}

.home-progress-tracker.green .ant-steps-icon-dot {
  background: var(--default-green) !important;
}

.home-progress-tracker.red .ant-steps-icon-dot {
  background: var(--default-red) !important;
}

.home-progress-tracker .ant-steps-icon-dot {
  width: 12px !important;
  height: 12px !important;
  top: -3px !important;
  left: -3px !important;
}

.progress-pagination .ant-pagination-simple .ant-pagination-simple-pager input {
  background-color: var(--default-cta);
  border: 1px solid var(--default-cta);
}

.progress-tracker-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  /* left: 20px; */
  font-weight: 600;
  left: 10px;
  margin-top: 20px;
  line-height: 1.3;
  color: var(--light-button-blue);
}

.progress-tracker-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  /* left: 20px; */
  font-weight: 600;
  left: 10px;
  margin-top: 20px;
  line-height: 1.3;
  color: var(--default-black);
}

.progress-tracker-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  /* left: 20px; */
  font-weight: 600;
  left: 10px;
  margin-top: 20px;
  line-height: 1.3;
  color: var(--default-black);
}

.ant-progress-bg {
  height: 20px !important;
}

.progress-tracker-bar .ant-progress-bg {
  height: 22px !important;
}

.progress-tracker-bar .ant-progress-inner {
  box-shadow: inset 0px 8px 10px var(--secondary-background);
}

.no-metric .ant-steps-dot .ant-steps-item-tail::after,
.progress-tracker-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  background-color: var(--secondary-background) !important;
  width: 100% !important;
  height: 10px !important;
  box-shadow: inset 0px 8px 10px var(--light-shade);
}

.no-metric .ant-steps-dot .ant-steps-item-tail,
.progress-tracker-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 8px !important;
}

.no-metric.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  box-shadow: 0px 8px 10px var(--default-box-shadow-color);
  width: 24px;
  height: 24px;
  border: 4px solid var(--default-white);
}

.no-metric.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--secondary-background);
}

.red-metric.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--default-red);
}

.amber-metric.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--default-cta);
}

.green-metric.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--default-green);
}

/* changes here */

.no-metric.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  /* box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25); */
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border: 4px solid var(--light-button-blue);
}

.no-metric.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--secondary-background);
}

.red-metric.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--default-red);
}

.amber-metric.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--default-cta);
}

.green-metric.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--default-green);
}

/* Finish changes here */

.no-metric.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  /* box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25); */
  box-shadow: 0px 8px 10px var(--light-shade);
  width: 24px;
  height: 24px;
  border: 4px solid var(--default-white);
}

.no-metric.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--secondary-background);
}

.progress-tracker-header>.ant-page-header-heading {
  width: 100%;
}

.red-metric.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--default-red);
}

.amber-metric.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--default-cta);
}

.green-metric.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--default-green);
}

/* Scenarios Styles */

.scenario-list-input.ant-input::placeholder {
  color: var(--default-grey) !important;
  font-weight: 500;
}

.scenario-list-select-input .ant-select-selection-placeholder {
  color: var(--default-grey) !important;
  font-weight: 500;
}

.scenario-list-select-input .ant-select-arrow {
  right: -11px;
}

.scenarios-share-modal .ant-modal-body {
  padding: 0 !important;
}

.scenarios-share-modal .ant-modal-header {
  padding: 24px !important;
  border-bottom: none !important;
  border-radius: 8px !important;
}

.scenarios-share-modal .ant-modal-title {
  font-size: 20px;
}

.create-scenario-error-form-item .ant-form-item-explain-error {
  font-style: italic;
  margin-bottom: 10px;
}

.editable-text-scenario-create {
  margin-bottom: 10px !important;
}

.create-scenario-description .ant-form-item-explain-error {
  margin-top: 8px !important;
}

.create-scenario-technology .ant-form-item-explain-error {
  margin-top: 8px !important;
}

.create-scenario-technology-specific-select .ant-select-selector {
  background-color: var(--default-cta) !important;
  height: 35px !important;
  align-items: center !important;
}

.create-scenario-technology-specific-select-disabled .ant-select-selector {
  background-color: var(--secondary-background) !important;
  height: 35px !important;
  align-items: center !important;
}

.create-scenario-technology-specific-select-disabled .ant-select-selection-placeholder {
  color: var(--grey-shade) !important;
}

.create-scenario-technology-specific-select .ant-select-selection-placeholder {
  color: var(--default-white) !important;
}

.technologies-tree {
  /* width: fit-content; */
  width: 100% !important;
  border: 1px solid var(--secondary-background) !important;
  border-radius: 8px !important;
  box-shadow: 0px 8px 10px var(--light-grey) !important;
}

.technologies-tree .ant-tree-treenode.ant-tree-treenode-switcher-open {
  width: 100%;
  padding: 10px 20px;
  padding-right: 40px;
}

.technologies-tree .ant-tree-treenode.ant-tree-treenode-switcher-close {
  width: 100%;
  padding: 10px 20px;
  padding-right: 40px;
}

.technologies-tree .ant-tree-treenode.ant-tree-treenode-switcher-open:first-child {
  border-bottom: 1px solid var(--secondary-background);
}

.technologies-tree .ant-tree-title {
  font-weight: 600 !important;
}

.technologies-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: var(--default-cta) !important;
  border-color: var(--default-cta) !important;
}

.technologies-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  border-color: var(--default-cta) !important;
}

.technologies-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: var(--default-cta) !important;
}

.set-budget-select-input .ant-select-selector {
  background-color: var(--default-cta) !important;
  color: var(--default-white) !important;
  height: 35px !important;
  align-items: center !important;
}

.set-budget-select-input .ant-select-selection-placeholder {
  color: var(--default-white) !important;
}

.set-budget-radio-input .ant-radio-checked .ant-radio-inner {
  border-color: var(--default-cta) !important;
}

.set-budget-radio-input .ant-radio-inner::after {
  background-color: var(--default-cta) !important;
}

.set-budget-input .ant-form-item-explain {
  position: absolute;
  left: 45px !important;
}

/* Output Scenario Css Styles */
.output-scenario-collapse {
  background-color: unset !important;
}

.output-scenario-collapse .ant-collapse-header {
  padding: 0px !important;
  width: 300px !important;
}

.output-scenario-collapse .ant-collapse-content {
  background-color: var(--default-white) !important;
  margin-top: 15px;
  border: 1px solid var(--light-grey);
  border-radius: 5px !important;
}

.output-scenario-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 0px !important;
}

.output-scenario-main-table .ant-table-thead>tr>th {
  background: var(--default-white) !important;
  border: none !important;
}

.output-scenario-map-drawer-close .ant-drawer-content-wrapper {
  width: calc(100vw - 85px) !important;
  transform: translateX(0px) !important;
  margin-right: 0px !important;
}

.output-scenario-map-drawer-close-consultation .ant-drawer-content-wrapper {
  margin-right: 30px !important;
}

.output-scenario-map-drawer-open .ant-drawer-content-wrapper {
  width: calc(100vw - 230px) !important;
  transform: translateX(0px) !important;
}

.output-scenario-map-consultation .ant-drawer-content-wrapper {
  width: 100vw !important;
  transform: translateX(0px) !important;
}

.output-scenario-map-drawer-insider .ant-drawer-content-wrapper {
  width: 360px !important;
}

.scenario-modal-drawer .ant-drawer-content-wrapper {
  width: 100% !important;
}

.output-scenario-map-drawer-insider .ant-drawer-wrapper-body {
  background-color: var(--default-white) !important;
  height: 100% !important;
  border-radius: 0 !important;
}

/* Cost Templates Styles */

.cost-templates-table .ant-table-thead>tr>th {
  font-weight: 400 !important;
}

.budget-slider-scenario-form.ant-slider-with-marks {
  margin-bottom: 16px !important;
}

.budget-allocation-switch.ant-switch-checked {
  background-color: var(--default-cta) !important;
}

.budget-allocation-switch .ant-switch-handle:before {
  border-radius: 9px !important;
}

/* Guidance styles */

.guidance-tabs .ant-tabs-nav::before {
  border-bottom: none !important;
}

.guidance-icon {
  border-radius: 30px;
  padding: 0px 5px;
  font-size: 13px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border: 1px solid var(--default-white);
}

.guidance-tabs .ant-tabs-tab.ant-tabs-tab-active .guidance-icon {
  border: 1px solid var(--primary-background);
}

.ant-modal.share-modal .ant-modal-header,
.ant-modal.share-modal .ant-modal-footer {
  border: none;
}

.ant-modal.share-modal .ant-list-item {
  border: none;
  padding-inline: 0;
}

.select-lsoa-output .ant-select-selector {
  background-color: var(--default-cta) !important;
  color: var(--default-white) !important;
  height: 35px !important;
  align-items: center !important;
}

.select-lsoa-output .ant-select-selection-placeholder {
  color: var(--default-white) !important;
}

.scenario-map-dropdown {
  display: block;
  width: 100%;
  margin-block: 10px;
}

.scenario-map-dropdown-overlay {
  opacity: 0.3;
}

.scenario-map-dropdown .ant-select-selector {
  background-color: var(--light-blue) !important;
  height: 40px !important;
  display: flex;
  align-items: center;
}

.scenario-map-dropdown .ant-select-arrow {
  color: var(--default-grey) !important;
}

.scenario-map-dropdown .ant-select-dropdown {
  background-color: var(--light-blue) !important;
}

.scenario-map-dropdown .ant-select-item-option-selected,
.scenario-map-dropdown .ant-select-item-option-active {
  background-color: var(--default-white) !important;
  /* color: white !important; */
}

/* Commented incase future use */
/* .scenario-map-dropdown .ant-select-selection-placeholder {
  color: var(--default-black) !important;
} */

.set-budget-select-cost-template .ant-select-selector {
  height: 40px !important;
}

.output-scenario-main-table .ant-pagination {
  background-color: unset !important;
}

.dashboard-alert .ant-alert-icon {
  font-size: 18px !important;
  margin: 4px !important;
}

.dashboard-alert {
  height: auto;
}

.dashboard-alert .ant-alert-info {
  border: 1px solid var(--border-blue) !important;
}

.technology-target-collapse .ant-collapse-content {
  border-top: none !important;
}

.technology-target-collapse .ant-collapse-content {
  background-color: inherit !important;
}

.technology-target-collapse .ant-collapse {
  background-color: none !important;
  border: none !important;
}

.technology-target-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding-top: 0;
}

.scenario-table-list-dropdown .ant-dropdown-menu-item:hover {
  background-color: var(--dropdown-item-hover) !important;
}

.scenario-error-modal .ant-result-content {
  margin-top: 0px !important;
}

.scenario-error-modal .ant-result-icon {
  display: none !important;
}

.scenario-error-modal .ant-result-title {
  font-size: 20px !important;
  text-align: left !important;
  margin-left: 40px !important;
}

.resources-tab .ant-menu-submenu-title {
  display: flex !important;
}

.scenario-back-icon .ant-page-header-back {
  margin-right: 0 !important;
}

.dfes-data-table .ant-pagination.ant-pagination-mini .ant-pagination-next,
.ant-pagination.ant-pagination-mini .ant-pagination-prev {
  margin: 0 3px !important;
}

.dfes-data-table .ant-pagination-next button {
  color: var(--default-white);
}

.dfes-data-table .ant-pagination-prev button {
  color: var(--default-white);
}

.dfes-data-table .ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: var(--default-grey);
  /* background-color: var(--light-shade) !important; */
}

.dfes-data-table .ant-table-body {
  scrollbar-width: thin !important;
}

.dfes-data-table tr:nth-child(2n) td {
  background-color: var(--light-grey) !important;
}

.dfes-data-table .ant-table-pagination.ant-pagination {
  margin: 0 0 16px 0 !important;
}

/* guidance menu styles (tree) */
.guidance-menu {
  padding: 16px !important;
  height: 81vh;
  overflow-y: scroll !important;
}

.guidance-menu .ant-tree-treenode-switcher-close,
.guidance-menu .ant-tree-treenode-switcher-open {
  flex-flow: row-reverse !important;
}

.guidance-menu .ant-tree-switcher {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  font-size: 24px !important;
}

.guidance-menu .ant-tree-switcher svg {
  font-size: 16px !important;
  color: var(--default-grey) !important;
}

.guidance-menu .ant-tree-node-content-wrapper {
  padding: 8px !important;
}

.guidance-menu .ant-tree-treenode-selected,
.guidance-menu .ant-tree-node-selected,
.guidance-menu .ant-tree-treenode:hover {
  background-color: var(--light-blue) !important;
}

.guidance-menu .ant-tree-treenode {
  margin-bottom: 4px !important;
}

.guidance-menu .ant-tree-node-content-wrapper:hover {
  background-color: transparent !important;
}

.guidance-content {
  background-color: var(--default-white);
  margin: 0 auto;
  width: 100%;
  height: 81vh;
  overflow-y: scroll;
}

/* use cases */

.usecase-page {
  margin: 0 100px;
  background-color: var(--default-white);
  padding: 30px 50px;
}

.left-asset-details {
  width: auto;
  margin-left: 20px;
}

.blue-box {
  width: 70%;
}

.usecase-step1 {
  display: flex;
}

.usecase-step1-blue-box {
  margin: 30px 0 0 0;
  height: 20vh;
  background: var(--primary-background);
  border-radius: 24px;
  padding: 20px 40px;
  color: var(--default-white);
  width: 70%;
}

.usecase-step1-question-box {
  background-color: var(--default-white);
  padding: 10px;
  margin-left: 50px;
  width: 22%;
}

/* la selection style */

.la-selection .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--default-cta) !important;
}

.la-selection .ant-tabs-tab:hover {
  color: black !important;
}

.dso-dashboard-list .ant-list-split .ant-list-item {
  border-bottom: white !important;
}

.dso-dashboard-list .ant-modal-header {
  background-color: #52c41a2e !important;
}

.dso-statistic {
  background-color: var(--default-white);
  max-width: 200px;
  border-radius: 8px !important;
}

.dso-statistic-shared-page {
  background-color: var(--default-white);
  min-width: 260px;
  border-radius: 0 !important;
}

.dso-feature-table .ant-table-column-sorter {
  color: black !important;
}

/* base map */

.base-map {
  height: 79px;
  width: 320px;
  background-color: var(--default-white);
  position: absolute;
  bottom: 0px;
  left: -340px;
  border-radius: 8px;
  box-shadow: 0 1px 3px var(--default-box-shadow-color),
    0 4px 8px 3px var(--secondary-box-shadow-color);
  padding: 10px 16px 6px;
  display: flex;
}

/* progress tracker */

.progress-main-page {
  display: flex;
  padding-bottom: 4em;
  width: 100% !important;
}

.progress-steps {
  width: 100%;
  overflow-x: scroll;
  margin-top: 30px;
  padding: 10px 110px 10px 0;
}

.access-guidance {
  border-top: 4px solid var(--default-cta);
  background-color: var(--default-white);
  height: 80vh;
  overflow-y: scroll;
  padding: 15px 10px 20px 20px;
}

.masterplan-guidance {
  border-top: 4px solid var(--default-cta);
  background-color: var(--default-white);
  overflow-y: scroll;
  height: 80vh;
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.access-manage-table {
  width: 100%;
  margin-right: 10px;
}

.access-manage-guidance {
  width: 30%;
}

.manage-access {
  width: 100%;
  padding: 1.5rem 1rem;
  overflow-y: scroll;
  padding-top: 0;
  display: flex;
  /* margin-top: 10px; */
}

.expiry-datepicker .ant-picker {
  border: 1px solid #bfbfbf;
}

.access-main-page {
  width: 75%;
  background-color: var(--default-white);
  margin-right: 10px;
}

/* invite users screen */

.invite-page {
  width: 100%;
  height: 82vh;
  padding-top: 0;
  display: flex;
  margin-left: 0 auto;
  margin-top: 10px;
}

.invite-card {
  width: 70%;
  margin-right: 10px;
  overflow-y: scroll;
}

.assets-guide {
  border-top: 4px solid var(--default-cta);
  background-color: var(--default-white);
  height: 82vh;
  overflow-y: scroll;
  margin-right: 10px;
  padding: 15px 10px 20px 20px;
}

/* user guidance */

.guidance-page {
  padding: 10px 24px 24px 24px;
  margin: 0px 24px;
  display: grid;
  grid-template-columns: 20% 79%;
  gap: 1%;
}

.timeseries-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
  margin-left: 20px;
}

.scenario-card {
  /* display: grid; */
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr; */
  gap: 70px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.scenario-commom-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 40px;
}

.reports-layout {
  height: 100vh;
  margin: 5px;
  display: flex;
}

/* scenario */

.scenario-main-page {
  padding: 0 40px;
  padding-top: 40px;
  width: 100%;
  height: 100vh;
  display: flex;
}

.scenario-left-page {
  width: 75%;
  height: 100vh;
  margin-right: 10px;
  overflow-y: scroll;
  padding-right: 10px;
  padding-bottom: 16em;
}

.scenario-form {
  margin-top: 10px;
  display: grid;
  grid-template-areas: ". ." ". .";
  grid-row-gap: 20px;
  grid-column-gap: 10em;
  padding: 30px 0;
  padding-top: 10px;
}

.scenario-budget-page {
  display: flex;
  align-items: center;
}

.scenario-guidance-page {
  width: 25%;
}

.Scenario-slider .ant-slider-track {
  height: 8px !important;
  background-color: #4485ea !important;
  border-radius: 5px !important;
}

.Scenario-slider .ant-slider-rail {
  height: 8px !important;
  background-color: #7e7e7e !important;
  border-radius: 5px !important;
}

.Scenario-slider .ant-slider-dot,
.Scenario-slider .ant-slider-dot-active {
  display: none !important;
}

.share-scenario-form .ant-form-item-control-input-content {
  max-width: 50% !important;
}

.Scenario-slider .ant-slider-handle {
  height: 20px !important;
  margin-top: -7px;
  border: solid 1px gray;
  border-radius: 20px;
  width: 10px;
}

.share-scenario-form .ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label,
.ant-form-vertical .ant-form-item-label {
  padding: 0 !important;
}

.share-scenario-radio .ant-radio-inner:after {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}

.share-scenario-radio .ant-radio-checked .ant-radio-inner {
  border-color: #173d64;
}

.share-scenario-radio .ant-radio-inner {
  width: 18px;
  height: 18px;
}

.share-scenario-radio .ant-radio-inner {
  border: 1px solid #173d64;
}

.analytics-guide {
  height: calc(100vh - 325px);
  overflow-y: scroll;
  padding-right: 20px;
}

.legend {
  width: 275px;
}

.table-style .ant-table table {
  background-color: var(--default-white);
}

.shared-data-table-style .ant-table-thead>tr>th {
  background: none;
  border-bottom: 0;
  font-weight: 700;
}

.shared-data-table-style .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  background: none;
}

.shared-data-table-style .ant-table-thead>tr>th {
  background: none !important;
  border-bottom: 0 !important;
  font-weight: 700 !important;
}

.shared-data-table-style .ant-table-title {
  padding: 0px;
}

.shared-data-table-style .ant-table-tbody>tr>td {
  border-bottom: 0px;
}

.shared-data-table-style .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  background: none !important;
}

.hide-export-button .ant-table-title {
  padding: 0;
}

/* mainmenu */

.main-menu-below-icons {
  margin: -25px 3px 0px 5px;
  /* display: flex; */
  align-items: center;
  flex-direction: column;
  position: relative;
  bottom: 40px;
}

.output-scenario-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 15px;
}

.row-style {
  margin: 20px !important;
}

/* usecases */

.usecases-main-page {
  margin: 0 100px;
  background-color: var(--default-white);
  padding: 30px 50px;
}

.usecases-steps {
  display: flex;
}

/* home page know more section */
.home-know-more svg {
  width: 16px !important;
  height: 16px !important;
}

/* share plans */

.forecasts-main-page {
  display: flex;
  padding: 20px;
}

.quick-links-page {
  width: 30%;
  margin-left: 20px;
}

.share-plans-button {
  background-color: var(--default-cta) !important;
  color: var(--default-white) !important;
  border: none !important;
  border-radius: 4px;
  width: 100%;
}

.orange-info-container {
  display: flex;
  justify-content: center;
  margin: 0 50px;
}

.orange-info-box {
  color: var(--default-white);
  background-color: var(--default-cta);
  border: none;
  border-radius: 4px;
  margin-top: 24px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 25%;
  text-align: center;
}

.arrow-icon {
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.targets-box {
  display: flex;
}

.your-targets-content {
  width: 60%;
}

.your-target-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.our-forecasts-page {
  width: 70%;
  padding: 0 20px;
}

/* new user homepage */

.getting-started {
  background-color: var(--default-white);
  padding: 30px;
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alert-box {
  background-color: var(--default-white);
  padding: 30px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* dfes on datastories */

.dfes-timeline .ant-picker-date-panel,
.ant-picker-decade-panel,
.ant-picker-month-panel,
.ant-picker-quarter-panel,
.ant-picker-time-panel,
.ant-picker-week-panel,
.ant-picker-year-panel {
  height: 250px !important;
  border: 1px solid black;
  border-radius: 5px;
}

.dfes-timeline .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: var(--default-cta) !important;
  border-radius: 20px;
}

.dfes-timeline .ant-picker-focused,
.ant-picker:hover {
  border-radius: 20px !important;
}

.dfes-timeline {
  border-radius: 20px !important;
  border: 1px solid black !important;
}

.gotquestion-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.expiry-date-picker .ant-picker {
  border: 1px solid rgba(0, 0, 0, 0.45);
}

.expiry-date .ant-picker.ant-picker-disabled {
  background: #ccced5;
  border-radius: 5px;
}

.date-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--default-cta);
  border: none;
}

/* planning progress */

.planning-chart .apexcharts-legend-series {
  margin-bottom: 20px !important;
}

.shared-plans-mainmenu {
  width: 70%;
}

.shared-plans-sidemenu {
  width: 30%;
}

.shared-plans-page {
  display: flex;
  gap: 20px;
}

@media (max-width: 1020px) {
  .main-menu-below-icons {
    margin: -10px 3px 0px 5px;
    /* display: flex; */
    align-items: center;
    flex-direction: column;
  }

  .output-scenario-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
    margin-top: 15px;
  }

  .access-tabs .ant-tabs-nav-list {
    color: var(--default-white);
    border: 1px solid var(--light-grey);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    margin-left: 100px;
  }

  .forecasts-main-page {
    display: block;
    padding: 30px;
  }

  .quick-links-page {
    width: fit-content;
    margin: 20px 0 0 0;
  }

  .share-plans-button {
    background-color: var(--default-cta) !important;
    border: none;
    border-radius: 4px;
    width: fit-content;
  }

  .orange-info-container {
    display: block;
    justify-content: center;
    margin: 0 50px;
  }

  .orange-info-box {
    margin-top: 0;
    height: auto;
    width: auto;
  }

  .arrow-icon {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    transform: rotate(90deg);
  }

  .targets-box {
    display: block;
  }

  .your-targets-content {
    width: auto;
  }

  .your-target-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }

  .our-forecasts-page {
    width: auto;
    padding: 20;
  }

  .getting-started {
    background-color: var(--default-white);
    padding: 30px;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .alert-box {
    background-color: var(--default-white);
    padding: 30px;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* .gotquestion-div {
    border: 1px solid var(--secondary-background);
    border-radius: 20px;
    padding: 20px;
    margin: 20px 0;
  } */

  .output-scenario-parent {
    display: block !important;
  }

  .graph-cards-parent {
    width: 100% !important;
  }

  .scenario-consultation {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    height: fit-content;
    margin-top: 20px;
    width: 100% !important;
  }

  .cards-on-scenario {
    width: 100% !important;
  }

  .shared-plans-mainmenu {
    width: 100%;
  }

  .shared-plans-sidemenu {
    width: 100%;
  }

  .shared-plans-page {
    display: block;
  }

  .new-scenario-map-series {
    position: absolute !important;
    background-color: var(--primary-background) !important;
    height: 90px;
    color: var(--default-black);
    padding-left: 30px;
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    /* bottom: 35px; */
    width: 100% !important;
    bottom: 0px;
    /* margin-bottom: 4% !important; */
  }

  .portfolio_main_page {
    margin: 25px 15px;
    display: flex !important;
    flex-direction: column-reverse;
    height: 100vh;
    overflow-y: scroll;
  }

  .portfolio-guidance {
    margin-bottom: 20px !important;
  }

  .scenario-charts-open {
    margin-bottom: 33% !important;
  }
}

.geospatial-dropdown {
  background-color: var(--light-shade);
}

@media (min-width: 1024px) {
  .col-stakeholder-style-2 {
    order: 2;
  }

  .col-stakeholder-style-1 {
    order: 1;
  }

  #targets-page {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .scenario-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
  }
}

.categories-spin-upload .ant-spin-text {
  display: flex !important;
  justify-content: center !important;
}

/* user upload styles */

.user-upload-modal .ant-modal-body {
  padding-bottom: 0;
}

.userupload-modal .ant-tabs-tab {
  padding: 5px 30px;
}

.userupload-modal .ant-tabs-nav-list {
  background-color: var(--primary-background) !important;
  width: auto !important;
  color: var(--default-white) !important;
  padding: 2px !important;
}

.permission-modal-text {
  font-size: 14px !important;
}

.permission-group-select .ant-select-selector {
  border: 1px solid var(--default-grey) !important;
  border-radius: 4px !important;
}

/*story project styles*/
.story-project-segments {
  color: var(--default-white) !important;
}

.story-project-segments .ant-segmented-item:hover:not(.ant-segmented-item-selected) {
  color: var(--default-white) !important;
}

/* dfes changes */

.dfes-series-drawer .ant-drawer-content {
  background-color: transparent !important;
}

.dfes-series-drawer .ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.dfes-series-drawer .ant-drawer-body {
  padding: 0 !important;
}

.dfes-data-table-drawer .ant-modal-content {
  height: 99vh !important;
}

.select-dfes-scenario .ant-modal-header {
  padding: 20px 24px;
  background: #f2f1f7;
}

/* dfes on datastories changes */

.datastories-dfes-modal .ant-modal-content {
  padding: 12px 20px !important;
  height: 730px !important;
  width: 100% !important;
}

.datastories-dfes-modal .close-icon {
  font-size: 16px !important;
}

.custom-tools-datastory-dfes {
  right: 76px !important;
}

.sdfescoformoutputs__checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--default-cta);
  border: none;
}

.sdfescoformoutputs__select .ant-select-selector {
  background-color: var(--light-background-color) !important;
  height: 38px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 4px !important;
}

.scenario-map-description-collapse .ant-collapse-header {
  padding: 0px !important;
}

.layer-switcher-scenario-map .ant-select-selector {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 32px 10px,
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px;
}

.layer-switcher-scenario-map .ant-select-selection-item {
  font-weight: 600 !important;
}

.output-scenario-parent {
  display: flex;
}

.graph-cards-parent {
  width: 75%;
}

.modal-graph-cards-parent {
  width: 100%;
}

.scenario-consultation {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  height: fit-content;
  /* width: 25%; */
}

.cards-on-scenario {
  width: 420px;
}

.top-menu .ant-menu-submenu-title {
  display: flex;
  color: white;
  gap: 5px;
}

.output-drawer-scenario-pageheader .ant-page-header-heading-extra {
  display: flex;
  justify-content: center;
  margin-right: 10em;
  flex: 1;
}

.output-drawer-scenario-tabs .ant-tabs-ink-bar {
  display: none !important;
}

.output-drawer-scenario-tabs .ant-tabs-nav-list {
  border: 1px solid !important;
  width: 100% !important;
}

.output-drawer-scenario-tabs .ant-tabs-tab {
  padding: 7px 20px !important;
}

.output-drawer-scenario-tabs .ant-tabs-tab-active {
  background-color: white !important;
}

.output-drawer-scenario-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black !important;
}

.output-drawer-scenario-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin-left: 0;
}

.output-drawer-scenario-tabs .ant-tabs-nav {
  margin-bottom: 0px !important;
}

.dso-getla-modal .ant-modal-confirm-btns {
  display: none !important;
}

.budget-range-list-projects li::marker {
  font-size: 1rem;
}

.scenario-map-charts-drawer .ant-drawer-content-wrapper {
  height: auto !important;
  bottom: 0px !important;
  width: 75% !important;
  margin-left: 25% !important;
  margin-bottom: 8% !important;
}

.common-map-charts-drawer .ant-drawer-content-wrapper {
  bottom: 90px !important;
  height: 215px !important;
  /* position: absolute; */
}

.portfolio-table .ant-table-tbody>tr>td {
  font-size: 13px;
}

.portfolio_main_page {
  margin: 25px 15px;
  display: grid;
  grid-template-columns: 75% 24%;
  gap: 1%;
}

.collapse-container-portfolio .ant-collapse-header {
  background-color: #44946c !important;
  border-radius: 8px !important;
  color: white !important;
}

.collapse-container-portfolio .ant-collapse-item {
  border: none !important;
  margin-bottom: 7px !important;
}

.collapse-container-portfolio .ant-collapse-content {
  border-top: 0px !important;
}

.collapse-container-portfolio .ant-collapse-content>.ant-collapse-content-box {
  padding: 0px !important;
  border: 1px solid #d9d9d9;
  border-top: 0px !important;
  margin-top: -6px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.portfolio-field-form-select .ant-select-selector {
  border-radius: 4px !important;
}

.collapse-container-portfolio .ant-collapse-header {
  align-items: center !important;
}

.portfolio-field-form-slider .ant-slider-rail {
  background-color: #757575 !important;
}

/* .custom-styling.ruleGroup {
  background-color: rgba(0,160,52,.3);
  border-color: transparent !important;
  box-shadow: inset 2px 2px 6px 0 rgba(0,0,0,.4) !important;
  padding: 1rem !important
}

.combinator-ant-select .ant-select-selector {
  height: 24px !important;
  align-items: center !important;
}

.combinator-ant-select .ant-select {
  font-size: 13px !important;
}

.custom-styling .ruleGroup-combinators {
  height: 24px !important;
} */

.custom-styling {
  .ruleGroup {
    border-color: rgba(0, 0, 0, 0);
    background-color: #44946c94 !important;
    box-shadow: inset 2px 2px 6px 0 rgb(0 0 0 / 40%);
    padding: 1rem;
  }

  /* .rule-remove {
    display: flex;
    background: none;
    color: #0068ff;
    border: none;
    text-shadow: 2px 2px 3px #3333334d;
    filter: drop-shadow(2px 1px 1px red);
  } */

  /* .ruleGroup-remove {
    display: flex;
    color: #0068ff;
    border-color: #0068ff !important;
    box-shadow: none !important;
    margin-bottom: 0px !important;
    padding-left: 3px;
    padding-bottom: 7px;
    background: none !important;
    border: none !important;
    font-size: 24px !important;
    display: flex;
    align-items: center;
  } */

  /* .ruleGroup-remove span {
    font-family: monospace;
    font-size: 28px !important;
    filter: drop-shadow(2px 1px 1px #ff663399);
  } */

  /* .ruleGroup-remove:focus {
    background: none !important;
    color: #0068ff !important;
  } */

  .rule-cloneRule {
    color: #ffffff;
    /* filter: drop-shadow(2px 1px 1px #3f8c4b); */
  }

  .ruleGroup-addRule {
    /* margin-left: auto; */
    background-color: #377af6;
  }

  .ruleGroup-addRule:hover {
    background-color: #377af6;
    border-color: rgba(52, 52, 255, 0.5);
  }

  .ruleGroup-addRule:focus {
    background-color: rgba(52, 52, 255, 0.5);
    border-color: rgba(52, 52, 255, 0.5);
  }

  .ruleGroup-addGroup:hover {
    background-color: #357b58;
  }

  .ruleGroup-addGroup:focus {
    background-color: #357b58;
  }

  .ruleGroup-addGroup {
    background-color: #357b58;
  }

  .ruleGroup-addRule,
  .ruleGroup-addGroup {
    border: none;
    border-radius: 2px;
    box-shadow: 3px 3px 4px 0 rgb(0 0 0 / 40%);
    height: 28px;
  }

  .rule {
    row-gap: 0;

    .queryBuilder-dragHandle {
      margin-left: auto;
    }
  }

  .queryBuilder-dragHandle {
    color: #3366ff99;
    text-shadow: 2px 2px 3px #3333334d;
    order: 99;
  }
}

.portfolio-title .ant-page-header-heading {
  align-items: center;
}

.portfolio-title .ant-page-header-heading-left {
  margin: 0px;
}

.portfolio-outputs-table .ant-modal-body {
  padding: 0px 10px !important;
}

.deck-legend-popup .ant-popover-inner-content {
  padding: 0px !important;
}

.deck-map-tools-icon {
  border-radius: 4px !important;
  box-shadow: 0 1px 2px var(--default-box-shadow-color),
    0 1px 3px 1px var(--secondary-box-shadow-color) !important;
  font-size: 0.775rem !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 1.25rem !important;
  padding: 0 12px !important;
  padding-bottom: 2px !important;
  height: 30px !important;
}

.deck-map-tools-icon:focus {
  color: black !important;
  border-color: #dbb6b6 !important;
}

.uploadDataTab .ant-tabs-tab-active {
  background-color: green !important;
  color: red !important;
  border-radius: 8px;
  border-color: transparent !important;
}

.uploadDataTab .ant-tabs-tab {
  border: 1px solid black;
  padding: 5px;
  border-radius: 8px;
}

.uploadDataTab .ant-tabs-ink-bar {
  display: none !important;
  /* Hide the default tab indicator */
}

.geospatial-custom-tabs .ant-tabs-tab {
  padding: 0 !important;
  margin-top: 5px !important;
}

.geospatial-custom-tabs .ant-tabs-nav-list {
  padding-right: 10px !important;
}

.portfolio-project {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(68vh - 90px) !important;
  overflow: auto;
  padding-right: 10px;
}

.submit-form {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.common-asset-radio-options .ant-radio-button-wrapper {
  height: 25px !important;
  padding: 0 8px !important;
  font-size: 12px !important;
  line-height: 25px !important;
}

.common-asset-radio-options .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--default-cta);
  border-color: var(--default-cta);
}

.stakeholder-modal .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}